var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login d-flex justify-center" },
    [
      _c(
        "v-main",
        { staticClass: "d-flex" },
        [
          _c(
            "v-layout",
            {
              attrs: {
                fluid: "",
                flex: "",
                "flex-wrap": "",
                "justify-center": ""
              }
            },
            [
              _c(
                "v-flex",
                {
                  staticClass: "white",
                  attrs: {
                    "d-flex": "",
                    xs12: "",
                    sm5: "",
                    "align-center": "",
                    "elevation-0": ""
                  }
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "v-login-box rounded-0",
                      attrs: { flat: "", width: "100%" }
                    },
                    [
                      _c(
                        "v-layout",
                        { staticClass: "flex-column v-login-info" },
                        [
                          _c("v-img", {
                            attrs: {
                              "max-width": "200",
                              src: require("../assets/images/logo.png")
                            }
                          }),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              "Welcome to SearchAdsMaven, the conquesting \u0003expert for app marketers who want to win every search and achieve top results on the Apple App Store."
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        { staticClass: "py-4 px-0" },
                        [
                          true
                            ? _c("p", [
                                _c("strong", { staticClass: "red" }, [
                                  _vm._v(_vm._s(_vm.msg))
                                ])
                              ])
                            : _vm._e(),
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              attrs: {
                                action: _vm.action,
                                method: "POST",
                                role: "form"
                              },
                              model: {
                                value: _vm.valid,
                                callback: function($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid"
                              }
                            },
                            [
                              _c("input", {
                                attrs: { type: "hidden", name: "csrf_token" },
                                domProps: { value: _vm.csrf_token }
                              }),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "black--text mb-2 d-flex" },
                                    [_vm._v("Email Address")]
                                  ),
                                  _c("v-text-field", {
                                    staticClass: "rounded-0",
                                    attrs: {
                                      name: "identifier",
                                      required: "",
                                      outlined: "",
                                      dense: "",
                                      rules: _vm.emailRules
                                    },
                                    model: {
                                      value: _vm.email,
                                      callback: function($$v) {
                                        _vm.email = $$v
                                      },
                                      expression: "email"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "black--text mb-2 d-flex" },
                                    [_vm._v("Password")]
                                  ),
                                  _c("v-text-field", {
                                    staticClass: "rounded-0",
                                    attrs: {
                                      min: "6",
                                      "append-icon": _vm.toggle
                                        ? "visibility"
                                        : "visibility_off",
                                      type: _vm.toggle ? "text" : "password",
                                      required: "",
                                      outlined: "",
                                      dense: "",
                                      name: "password",
                                      rules: _vm.nameRules
                                    },
                                    on: {
                                      "click:append": function($event) {
                                        _vm.toggle = !_vm.toggle
                                      }
                                    },
                                    model: {
                                      value: _vm.password,
                                      callback: function($$v) {
                                        _vm.password = $$v
                                      },
                                      expression: "password"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-layout",
                                {
                                  attrs: { "justify-end": "", "flex-wrap": "" }
                                },
                                [
                                  _c("div", { staticClass: "mb-5" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text-center",
                                        attrs: { href: _vm.ory_url }
                                      },
                                      [_vm._v("Forgot password?")]
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "primary white--text mb-5 rounded-0",
                                  attrs: {
                                    type: "submit",
                                    block: "",
                                    depressed: "",
                                    large: "",
                                    disabled: !_vm.valid
                                  }
                                },
                                [_vm._v("Submit")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            {
                              staticClass: "justify-space-between mb-6",
                              attrs: { "flex-wrap": "" }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  attrs: { justify: "center", "flex-wrap": "" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { sm: "12", md: "12", lg: "6" } },
                                    [
                                      _c(
                                        "v-form",
                                        {
                                          ref: "form",
                                          attrs: {
                                            action: _vm.action_oidc,
                                            method: "POST",
                                            role: "form"
                                          },
                                          model: {
                                            value: _vm.valid,
                                            callback: function($$v) {
                                              _vm.valid = $$v
                                            },
                                            expression: "valid"
                                          }
                                        },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "hidden",
                                              name: "csrf_token"
                                            },
                                            domProps: { value: _vm.csrf_token }
                                          }),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "my-2 secondary--text v-social-btn rounded-0",
                                              attrs: {
                                                block: "",
                                                depressed: "",
                                                outlined: "",
                                                large: "",
                                                type: "submit",
                                                name: "provider",
                                                value: "google"
                                              }
                                            },
                                            [
                                              _c("v-img", {
                                                attrs: {
                                                  "max-height": "22",
                                                  "max-width": "22",
                                                  src: require("../assets/images/google.svg")
                                                }
                                              }),
                                              _c("span", [
                                                _vm._v("Sign in with Google")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { sm: "12", md: "12", lg: "6" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "my-2 secondary--text v-social-btn rounded-0",
                                          attrs: {
                                            block: "",
                                            depressed: "",
                                            outlined: "",
                                            large: ""
                                          }
                                        },
                                        [
                                          _c("v-img", {
                                            attrs: {
                                              "max-height": "22",
                                              "max-width": "22",
                                              src: require("../assets/images/linkedin.svg")
                                            }
                                          }),
                                          _c("span", [
                                            _vm._v("Sign in with Linkedin")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "v-login-info" }, [
                            _c("p", { staticClass: "mt-3" }, [
                              _vm._v(" Don't have an account "),
                              _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.goToRegistration()
                                    }
                                  }
                                },
                                [_vm._v("Sign Up?")]
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  staticClass: "v-login-right style-2",
                  attrs: { xs12: "", sm7: "", "elevation-0": "" }
                },
                [_c("h2", [_vm._v("Win every search.")])]
              ),
              _c(
                "v-dialog",
                {
                  attrs: { width: "300", persistent: "" },
                  model: {
                    value: _vm.loadingAlertFlag,
                    callback: function($$v) {
                      _vm.loadingAlertFlag = $$v
                    },
                    expression: "loadingAlertFlag"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "headline grey lighten-2" },
                        [_vm._v(" Info ")]
                      ),
                      _c("v-card-text", [
                        _vm._v(" " + _vm._s(_vm.loadingAlertText) + " ")
                      ]),
                      _c("v-divider")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }