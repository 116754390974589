<template flex>
  <div class="login d-flex justify-center">
    <v-main class="d-flex">
        <v-layout fluid flex flex-wrap justify-center>
          
          <v-flex d-flex xs12 sm5 align-center elevation-0 class="white">
            <v-card flat class="v-login-box rounded-0" width="100%">
                <v-layout class="flex-column v-login-info">
                  <v-img max-width="200" src="../assets/images/logo.png"></v-img>
                  <p class="mb-0">Welcome to SearchAdsMaven, the conquesting expert for app marketers who want to win every search and achieve top results on the Apple App Store.</p>
                </v-layout>
                <v-card-text class="py-4 px-0">
                    <p v-if="true" >
                        <strong class="red">{{ msg }}</strong>
                    </p>
                    <v-form v-model="valid" ref="form" :action="action" method="POST" role="form">
                        <input type="hidden" name="csrf_token" :value="csrf_token">
                        <div class="field">
                          <label class="black--text mb-2 d-flex">Email Address</label>
                          <v-text-field
                              v-model="email"
                              name="identifier"
                              required
                              outlined
                              class="rounded-0"
                              dense
                              :rules="emailRules"
                          ></v-text-field>
                        </div>
                        <div class="field">
                          <label class="black--text mb-2 d-flex">Password</label>
                          <v-text-field
                            v-model="password"
                            min="6"
                            :append-icon="toggle ? 'visibility' : 'visibility_off'"
                            :type="toggle ? 'text' : 'password'"
                            @click:append="toggle = !toggle"
                            required
                            outlined
                            class="rounded-0"
                            dense
                            name="password"
                            :rules="nameRules"
                          ></v-text-field>
                        </div>

                        <!-- <v-layout justify-end flex-wrap>
                          <div><a href="https://kochava.softobiz.net/ory/self-service/recovery/browser" class="text-center mb-4">Forgot password?</a></div>
                        </v-layout> -->

                        <v-layout justify-end flex-wrap>
                          <div class="mb-5"><a :href="ory_url" class="text-center">Forgot password?</a></div>
                        </v-layout>

                        <v-btn type='submit'
                          block
                          depressed large
                          class="primary white--text mb-5 rounded-0"
                          :disabled="!valid"
                        >Submit</v-btn>
                      </v-form>

                        <v-layout flex-wrap class="justify-space-between mb-6">
                          <v-row justify="center" flex-wrap>
                            <v-col sm="12" md="12" lg="6">
                              <v-form v-model="valid" ref="form" :action="action_oidc" method="POST" role="form">
                              <input type="hidden" name="csrf_token" :value="csrf_token">
                              <v-btn
                                block
                                depressed
                                outlined
                                large
                                class="my-2 secondary--text v-social-btn rounded-0"
                                type="submit"
                                name="provider"
                                value="google"
                              >
                                <v-img
                                  max-height="22"
                                  max-width="22"
                                  src="../assets/images/google.svg"
                                ></v-img>
                                <span>Sign in with Google</span>
                              </v-btn>
                              </v-form>
                            </v-col>
                            <v-col sm="12" md="12" lg="6">
                              <v-btn
                                block
                                depressed
                                outlined
                                large
                                class="my-2 secondary--text v-social-btn rounded-0"
                              >
                                <v-img
                                  max-height="22"
                                  max-width="22"
                                  src="../assets/images/linkedin.svg"
                                ></v-img>
                                <span>Sign in with Linkedin</span>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-layout>

                        <div class="v-login-info">
                          <p class="mt-3">
                            Don't have an account <a href="#" @click.prevent="goToRegistration()">Sign Up?</a>
                          </p>
                        </div>
                    <!-- </v-form> -->
                </v-card-text>
            </v-card>
          </v-flex>

          <v-flex xs12 sm7 elevation-0 class="v-login-right style-2">
            <!-- <v-card flat class="v-login-right-inner">
              <div class="pt-4 white--text">
                <h4 class="font-weight-medium pb-6">The Kochava Marketers Operating System™</h4>
                <p class="white-text">The Marketers Operating System (m/OS) seamlessly integrates omni-channel marketing solutions for advertisers and publishers in one operational platform.</p>
              </div>
              <v-card-text class="pt-4 px-0 white--text">
                <v-img src="../assets/images/login-img.svg"></v-img>
              </v-card-text>
            </v-card> -->
            <h2>Win every search.</h2>
          </v-flex>
          <v-dialog v-model="loadingAlertFlag" width="300" persistent>
            <v-card>
              <v-card-title class="headline grey lighten-2">
                Info
              </v-card-title>

              <v-card-text>
                {{ loadingAlertText }}
              </v-card-text>

              <v-divider></v-divider>

              <!-- <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="okAction"> Ok </v-btn>
              </v-card-actions> -->
            </v-card>
          </v-dialog>
        </v-layout>
    </v-main>
  </div>
</template>

<script>
import Vue from 'vue';
import { FlowService } from '@services/flow.service';
import { API_ORY_URL } from '@config'
export default Vue.extend({
  name: 'Signin',
  components: {
  },
    data () {
    return {
      ory_url: '',
      loadingAlertFlag: false,
      loadingAlertText: 'Fetching security token from Ory...',
      flowErr: true,
      email: "",
      password: "",
      valid:null,
      flow:null,
      action:null,
      action_oidc:null,
      csrf_token : null,
      showmsg: false,
      dialog:false,
      msg:"No msg",
      inputEmail: 'Input your E-Mail Id here',
      off: false,
      toggle:false,
      emailRules: [
        v => !!v || 'Email is required',
        v => /.+@.+\..+/.test(v) || 'Email must be valid',
      ],
      nameRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 6) || 'Password must be atleast 6 characters',
      ],
      fullscreenLoader: null,
    }
  },
  methods: {
    getFlow: async function (){
        //console.log('beforeMount')
        this.fullscreenLoader = this.$loading.show();
        this.msg = "";
        this.flow = this.$route.query.flow;
        //console.log("FlowServiceReq", this.flow);
        await FlowService.flow({ flowId: this.flow, type:'login' })
        .then((res) => {
          //console.log("FlowServiceRes--->",res);
          this.loadingAlertFlag = false;
          this.fullscreenLoader.hide();
          this.flowErr = false;
          this.action = res.data.action;
          this.action_oidc = res.data.action_oidc;
          this.csrf_token = res.data.token;
          if(res.data.message){
            this.showmsg = true;
            if(res.data.message == 'The provided credentials are invalid, check for spelling mistakes in your password or username, email address, or phone number.')
              this.msg = 'Invalid Credentials';
          }
        })
        .catch((err) => {
          //console.log(err);
          this.flowErr = true;
          this.loadingAlertFlag = false;
          this.fullscreenLoader.hide();
          if(err.message === 'Request failed with status code 410'){
              alert('Session expired. Click ok to refresh');
              window.location.href = API_ORY_URL + 'self-service/login/browser?refresh=true'
          }else {
            alert('Error : Server/Ory did not respond');
          }
        });
    },
    goToRegistration: function () {
      return this.$router.push('signup');
    },
    saveEmail() {
      //console.log('saveEmail');
    }
  },
  beforeMount(){
    this.ory_url = API_ORY_URL + 'self-service/recovery/browser'
    // console.log('beforeMount')
    this.getFlow()
  },
});
</script>

<style scoped>
.red {
 color: red;
}
.v-application .red {
  background-color: white !important
}
</style>
